import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.css'
import './Hero.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import firebaseConfig from '../../pages/firebase/firebaseConfig';

const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore(app);

const Hero = () => {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });

    const fetchSlides = async () => {
      try {
        const snapshot = await db.collection('heroslides').get();
        const fetchedSlides = snapshot.docs.map(doc => doc.data());
        setSlides(fetchedSlides);
      } catch (error) {
        console.error('Error fetching slides:', error);
      }
    };

    fetchSlides();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };
  

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="slide">
            <div
              className="slide-background"
              style={{ backgroundImage: `url(${slide.image})` }}
            >
              <div className="slide-content justify-content-start">
                <h5 class="text-white text-uppercase mb-3 animated slideInDown">{slide.subtitle}</h5>
                <h1 class="display-3 text-white animated slideInDown mb-4">{slide.title}</h1>
                <p class="fs-5 fw-medium text-white mb-4 pb-2">{slide.description}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Hero;
