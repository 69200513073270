import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import firebaseConfig from '../../firebase/firebaseConfig';

const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore(app);
const storage = firebase.storage(app);

const HomeProduct = () => {
  const [products, setProducts] = useState([]);
  const [editingItemId, setEditingItemId] = useState(null);
  const [formData, setFormData] = useState({
    hP: '',
    name: '',
    description: '',
    imageUrl: '',
  });
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const productsCollection = await db.collection('homepageproducts').orderBy("hP").get();
      const fetchedProducts = productsCollection.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(fetchedProducts);
    };

    fetchProducts();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this item?");
    if (confirmDelete) {
      await db.collection('homepageproducts').doc(id).delete();
      setProducts(products.filter(product => product.id !== id));
    }
  };

  const handleEdit = (item) => {
    setEditingItemId(item.id);
    setFormData(item);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'imageUrl' && files.length > 0) {
      setImageFile(files[0]);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleUpdate = async () => {
    let updatedData = { ...formData };

    if (imageFile) {
      const oldImageUrl = formData.imageUrl;
      const storageRef = storage.ref();
      const fileRef = storageRef.child(`upload/homepageproduct/img/${imageFile.name}`);
      await fileRef.put(imageFile);
      const newImageUrl = await fileRef.getDownloadURL();
      updatedData.imageUrl = newImageUrl;

      if (oldImageUrl) {
        const oldImageRef = storage.refFromURL(oldImageUrl);
        await oldImageRef.delete();
      }
    }

    await db.collection('homepageproducts').doc(editingItemId).update(updatedData);
    setProducts(products.map(item => (item.id === editingItemId ? { id: item.id, ...updatedData } : item)));
    setEditingItemId(null);
    setFormData({
      hP: '',
      name: '',
      description: '',
      imageUrl: '',
    });
    setImageFile(null);
  };

  const handleCancel = () => {
    setEditingItemId(null);
    setFormData({
      hP: '',
      name: '',
      description: '',
      imageUrl: ''
    });
    setImageFile(null);
  };

  return (
    <div className="container mt-5">
      <h2 style={{ textAlign: 'center', marginBottom: '1.5rem' }}>Home Page Products</h2>
      <table className="table table-striped table-bordered table-hover">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Image</th>
            <th scope="col">Name</th>
            <th scope="col">Description</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map(product => (
            <tr key={product.id}>
              <td>
                <img src={product.imageUrl} alt={product.name} style={{ width: '100px', height: 'auto' }} />
              </td>
              <td>{product.name}</td>
              <td>{product.description}</td>
              <td>
                <button className="btn btn-success btn-sm me-2" style={{ marginBottom: '1.5rem' }} onClick={() => handleEdit(product)}>Edit</button>
                <button className="btn btn-danger btn-sm" style={{ backgroundColor: 'red' }} onClick={() => handleDelete(product.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {editingItemId && (
        <div className="popup-container">
          <div className="popup-form">
            <h3>Edit Product</h3>
            <form>
              <div className="mb-3">
                <label>Image</label>
                <input
                  type="file"
                  className="form-control"
                  name="imageUrl"
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label>Product ID</label>
                <input
                  type="text"
                  className="form-control"
                  name="hP"
                  value={formData.hP}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label>Description</label>
                <input
                  type="text"
                  className="form-control"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </div>
              <button type="button" className="btn btn-success me-2" onClick={handleUpdate}>Update</button>
              <button type="button" className="btn btn-danger" onClick={handleCancel}>Cancel</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default HomeProduct;
