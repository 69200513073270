import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom"
import HomePage from './pages/homepage/HomePage'
import AboutPage from './pages/aboutpage/AboutPage'
import ContactPage from './pages/contactpage/ContactPage'
import ProductPage from './pages/productpage/ProductPage'
import Water from './pages/productpage/product/water/Water'
import Waste from './pages/productpage/product/waste/Waste'
import Pulp from './pages/productpage/product/pulp/Pulp'
import Oil from './pages/productpage/product/oil/Oil'
import Mining from './pages/productpage/product/mining/Mining'
import Sugar from './pages/productpage/product/sugar/Sugar'
import Metallurgy from './pages/productpage/product/metallurgy/Metallurgy'
import Auto from './pages/productpage/product/auto/Auto'
import Misc from './pages/productpage/product/misc/Misc'
import Pharma from './pages/productpage/product/pharma/Pharma'
import Privacy from './pages/privacy/Privacy'
import News from './pages/news/News'
import Disclaimer from './pages/disclaimer/Disclaimer'
import Login from './pages/login/Login'
import NavbarFooter from './pages/dashboard/dash-pages/NavbarFooter'
// import Admin from './pages/dashboard/Admin'
import Dashboard from './pages/dashboard/Dashboard'
import Admin from './pages/dashboard/Admin'
import ContactDetails from './pages/dashboard/dash-pages/ContactDetails'
import HeroForm from './pages/dashboard/dash-pages/form/HeroForm'

// products content






const App = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/product' element={<ProductPage />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/login' element={<Login />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/news' element={<News />} />
        <Route path='/disclaimer' element={<Disclaimer />} />

       {/* Dashboard Routes */}
       <Route path='/admin' element={<Admin />} />
       <Route path='/dashboard' element={<Dashboard />} />
       <Route path='/navbarfooter' element={<NavbarFooter />} />
       <Route path='/contactform' element={<ContactDetails />} />
       <Route path='/addhero' element={<HeroForm />} />


        {/* Products Routes */}
        <Route path='/water' element={<Water />} />
        <Route path='/waste' element={<Waste />} />
        <Route path='/pulp' element={<Pulp />} />
        <Route path='/oil' element={<Oil />} />
        <Route path='/mining' element={<Mining />} />
        <Route path='/sugar' element={<Sugar />} />
        <Route path='/metallurgy' element={<Metallurgy />} />
        <Route path='/auto' element={<Auto />} />
        <Route path='/miss' element={<Misc />} />
        <Route path='/pharma' element={<Pharma />} />
        
        
       
      </Routes>
    </Router>
  )
}

export default App
