import React, { useState, useEffect } from 'react';
import './Product.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import firebaseConfig from '../../pages/firebase/firebaseConfig';

const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore(app);

const Product = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const productsCollection = await db.collection('homepageproducts').orderBy("hP").get();
      const fetchedProducts = productsCollection.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(fetchedProducts);
    };

    fetchProducts();
  }, []);

  return (
    <div className="container-xxl py-5">
      <div className="container py-5">
        <div className="row card-container">
          {products.map(product => (
            <div key={product.id} className="col-md-6 col-lg-4 wow fadeInUp h-100" data-wow-delay="0.3s">
              <div className="service-item p-4 h-100">
                <div className="card" style={{ width: '18rem', height: '25rem' }}>
                  <img src={product.imageUrl} className="card-img-top" alt={product.name} style={{ height: '38%' }} />
                  <div className="card-body">
                    <h5 className="card-title pt-3">{product.name}</h5>
                    <p className="card-text pt-1">{product.description}</p>
                    <div className="overflow-hidden pt-auto">
                      <a className="btn-slide mt-2" href={product.link}><i className="fa fa-arrow-right"></i><span>Read More</span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Product;
