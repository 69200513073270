import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import firebaseConfig from '../../pages/firebase/firebaseConfig';
import { Link } from 'react-router-dom';
import "./Footer.css";

const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore(app);

const Footer = () => {
  const [contactInfo, setContactInfo] = useState({
    email: '',
    phone1: '',
    phone3: '',
    phone2: '',
    twitter: '',
    facebook: '',
    youtube: '',
    linkedin: ''
  });

  useEffect(() => {
    const fetchContactInfo = async () => {
      const contactDoc = await db.collection('navbar-footer').doc('XhlsFEOHJ7YNkT4aE01k').get();
      if (contactDoc.exists) {
        setContactInfo(contactDoc.data());
      }
    };

    fetchContactInfo();
  }, []);

  return (
    <div className="container-fluid bg-dark text-light footer pt-5 wow fadeIn" data-wow-delay="0.1s" style={{ marginTop: '6rem' }}>
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">Address</h4>
            <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>UK<br />
              Tel.: {contactInfo.phone1}<br />
              Fax.: {contactInfo.phone3}</p>
            <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>US<br />
              Tel.: {contactInfo.phone2}</p>
            <p className="mb-2"><i className="fa fa-envelope me-3"></i>{contactInfo.email}</p>
            <div className="d-flex pt-2">
              <a className="btn btn-outline-light btn-social" href={contactInfo.twitter} target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
              <a className="btn btn-outline-light btn-social" href={contactInfo.facebook} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
              <a className="btn btn-outline-light btn-social" href={contactInfo.youtube} target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
              <a className="btn btn-outline-light btn-social" href={contactInfo.linkedin} target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">Products</h4>
            <Link className="btn btn-link" to="/water">Water Treatment</Link>
            <Link className="btn btn-link" to="/pulp">Pulp and Paper</Link>
            <Link className="btn btn-link" to="/oil">Oil and Gas</Link>
            <Link className="btn btn-link" to="/sugar">Sugar Industry</Link>
            <Link className="btn btn-link" to="/auto">Automobiles Paint Booth</Link>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">Quick Links</h4>
            <Link className="btn btn-link" to="/about">About Us</Link>
            <Link className="btn btn-link" to="/contact">Contact Us</Link>
            <Link className="btn btn-link" to="/product">Our Services</Link>
            <Link className="btn btn-link" to="/privacy">Privacy and Policy</Link>
            <Link className="btn btn-link" to="/disclaimer">Disclaimer</Link>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">News and Updates</h4>
            <p><a href="/news">We attended SASCON Abu Dhabi</a><br /><span className="grid-post-date"></span></p>
            <div className="position-relative mx-auto" style={{ maxWidth: '400px' }}></div>
          </div>
        </div>
      </div>
      <div className="container text-center">
        <div className="copyright text-center">
          <div className="row text-center">
            <div className="col-md-6 text-center ">
              &copy; <a className="border-bottom text-center" href="#">Copyright</a> Speciality Chemicals Pvt Ltd.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
