import React from 'react'
import { Link } from 'react-router-dom';
import Layout from '../../../../component/layout/Layout'
import "./Pharma.css"

const Pharma = () => {
    return (
        <Layout>
             <div className="productP-banner">
                <div className="productP-content">
                    <h1>Pharmaceutical</h1>
                </div>
            </div>
            <div className="container-xxl py-5">
                <div className="container py-5">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="text-secondary text-uppercase"></h6>
                        <h1 className="mb-5"></h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="price-item">
                                <div className="border-bottom p-4 mb-4">
                                    <h5 className="text-primary mb-1">Products</h5>
                                    <h1 className="display-5 mb-0"></h1>
                                </div>
                                <div className="p-4 pt-0" style={{ textAlign: 'justify' }}>
                                    <div className="sub-products">
                                        <Link to="/water" className="dropdown-item">Water Treatment</Link>
                                        <Link to="/waste" className="dropdown-item">Waste Water Treatment</Link>
                                        <Link to="/pulp" className="dropdown-item">Pulp and Paper</Link>
                                        <Link to="/oil" className="dropdown-item">Oil and Gas</Link>
                                        <Link to="/mining" className="dropdown-item">Mining</Link>
                                        <Link to="/sugar" className="dropdown-item">Sugar Industry</Link>
                                        <Link to="/metallurgy" className="dropdown-item">Metallurgy</Link>
                                        <Link to="/auto" className="dropdown-item">Automobile Paint Booth</Link>
                                        <Link to="/miss" className="dropdown-item">Misscellaneous</Link>
                                        <Link to="/pharma" className="dropdown-item">Pharmaceutical</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-8 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="price-item">
                                <div className="border-bottom p-4 mb-4">
                                    <h1>Pharmaceutical</h1>
                                </div>
                                <div className="p-4 pt-0">
                                    <ul>
                                        <li>
                                            <h5>Methyl Salicylate (Flavour/Fragrance Grade)</h5>
                                        </li>
                                    </ul>
                                    <div className="pharma-container">
                                        <img src="img/MethylSalicylateFlavour.jpg" alt="Methyl Salicylate Flavour" />
                                    </div>
                                    <div className="about-para">
                                        <p>
                                            Description: Colourless Aromatic Liquid having a strong, persistent and characteristic Odour.
                                            C8H8O3 (M.W. 152.15)) Purity (Content of Methyl Salicylate): Minimum 99.7% Impurities: Extremely low heavy metals and phenol. Solubility: Complete and clear (1:7 ml of 70% Ethanol) Refractive Index: 1.535 to 1.538 Specific Gravity: 1.180 to 1.185 Acidity: 0.5 mgKOH/g maximum Uses: As a flavouring agent in toothpastes, toothpowders, food products, mouthwashes, oral care products
                                        </p>
                                    </div>
                                    <br /><br />
                                    <ul>
                                        <li>
                                            <h5>Methyl Salicylate IP/BP</h5>
                                        </li>
                                    </ul>
                                    <div className="pharma-container">
                                        <img src="img/MethylSalicylate.jpg" alt="Methyl Salicylate" />
                                    </div>
                                    <div className="about-para">
                                        <p>
                                            Description: Colourless Aromatic Liquid having a strong, persistent and characteristic Odour.
                                            C8H8O3 (M.W. 152.15)) Purity (Content of Methyl Salicylate): Minimum 99% Solubility: Complete and clear (1:7 ml of 70% Ethanol) Refractive Index: 1.535 to 1.538 Specific Gravity: 1.180 to 1.185 Acidity: 0.5 mgKOH/g maximum Uses: In all pain balms and analgesic ointments. Also used to mask undesirable odours etc.
                                        </p>
                                    </div>
                                    <br /><br />
                                    <ul>
                                        <li>
                                            <h5>Salicylamide NF</h5>
                                        </li>
                                    </ul>
                                    <div className="pharma-container">
                                        <img src="img/SalicylamideNF.jpg" alt="Salicylamide NF" />
                                    </div>
                                    <div className="about-para">
                                        <p>
                                            Chemical Name: O-Hydroxy Benzamide C7H7NO2 (M.W. 137.14)) Technical Information: White or slightly pink crystalline powder. Bitter in taste, sensation of warmth on tongue, forms water soluble Sodium Salt at 9pH. Description: A colourless crystalline powder. Odour: Almost Odourless. Melting Range: 139°C - 142°C. Solubility: Soluble in hot water, chloroform and ether. pH of saturated aqueous solution at 25°C is 5.0. Solubility in water at 30°C is 0.2%, at 47°C is 0.8%; Solubility in Glycerol at 5°C is 2.0%; at 39°C is 5%; at 60°C is 10%. Solubility in Propylene Glycol at 5°C is 10%. Infra red absorption: Passes test. Ultraviolet absorption: Passes test. Water: 0.5% (max). Residue on ignition: 0.05% w/w (max) N.F.XIII/0.1% w/w/U.S.P. Chromatographic Purity: Complies with U.S.P. test. Organic Volatile Impurities: Complies with U.S.P. test. Heavy Metals: 0.001% (U.S.P. limit). Assay: 98.0% to 102% of C7H7NO2 calculated on the anhydrous basis.
                                        </p>
                                    </div>
                                    <br /><br />
                                    <ul>
                                        <li>
                                            <h5>Salicylic Acid IP/BP/USP</h5>
                                        </li>
                                    </ul>
                                    <div className="pharma-container">
                                        <img src="img/salicylic-acid (1).jpg" alt="Salicylic Acid" />
                                    </div>
                                    <div className="about-para">
                                        <p>
                                            Description: Colourless crystalline powder. C7H6O3 (M.W. 138.12)) Odour: Almost Odourless. Melting Range: 158.5°C to 161.0°C. Appearance of Solution/Clarity & Colour of Solution: Passes test as per B.P./I.P Heavy Metals: 20 ppm (max.) Sulphated Ash/Residue on Ignition: 0.1% w/w (I.P./B.P.Limit) / 0.05%w/w (U.S.P. Limit) Chloride: 125 ppm (max.) I.P./100 ppm (max.) B.P. Sulphate: 0.02% (max.) I.P./140 ppm U.S.P. Related Substance: Complies with the B.P. test Loss of Drying: 0.5% (max.) B.P. Iron: 2 ppm (max.) I.P. Readily Carbonisable Substance: Passes test Assay: 99.0% to 100.5% I.P./B.P., 99.5% to 101% U.S.P. of C7H6O3 with reference to substance dried over Silica Gel for three hours.
                                        </p>
                                    </div>
                                    <br /><br />
                                    <ul>
                                        <li>
                                            <h5>Salicylate Acid</h5>
                                        </li>
                                    </ul>
                                    <div className="pharma-container">
                                        <img src="img/salicylic-acid.jpg" alt="Salicylic Acid" />
                                    </div>
                                    <div className="about-para">
                                        <p>
                                            Description: Colourless Aromatic Liquid having a strong, persistent and characteristic Odour. C15H22O3 (M.W. 250.33 g/mol)) Purity (Content of Methyl Salicylate): Minimum 99.0% Refractive Index: 1.500 to 1.503 Specific Gravity: 1.011 to 1.016 Acidity: 0.2 mgKOH/g maximum Uses: Its primary use is in sunscreens and other cosmetics to absorb UV-B rays from the sun, protecting the skin from damage
                                        </p>
                                    </div>
                                    <br /><br />
                                    <ul>
                                        <li>
                                            <h5>Octal Salicylate</h5>
                                        </li>
                                    </ul>
                                    <div className="pharma-container">
                                        <img src="img/octal-salicylate.jpg" alt="Octal Salicylate" />
                                    </div>
                                    <div className="about-para">
                                        <p>
                                            Description: Transparent, colorless crystals. C9H8O4 (M.W. 180.157 g/mol)) Purity: Minimum 99.5% Refractive Index: 1.500 to 1.503 Specific Gravity: 1.40 Acidity: 0.2 mgKOH/g maximum Uses: Its used for aches and pains for headaches, period pains, toothache, the pain of bruises and other injuries, and after dental treatment
                                        </p>
                                    </div>
                                    <br /><br />
                                    <ul>
                                        <li>
                                            <h5>Salicylic Acid (Technical)</h5>
                                        </li>
                                    </ul>
                                    <div className="pharma-container">
                                        <img src="img/salicylic-acid.jpg" alt="Salicylic Acid" />
                                    </div>
                                    <div className="about-para">
                                        <p>
                                            Chemical Name: O-Hydroxy Benzoic Acid C7H6O3 (M.W. 138.12)) Technical Information: White crystals or nearly white powder in two grades, i.e. Technical Salicylic Acid and Salicylic Acid IP/BP/USP. Sublimes readily. pH of saturated solution is 2.4 Solubility: 1 gm dissolves in 460ml of cold water, 15 ml of boiling water, 2.7 ml of alcohol, 3 ml of acetone, 42ml of chloroform, 3ml of ether, 135ml of benzene, 52ml oil turpentine, 60ml of glycerol and 80ml of fats & oil Description: A crystalline powder of not more than reddish buff colour. Melting Point: Not less than 156.5°C Ash: 0.5% (max) Assay: (Total acidity as Salicylic Acid) 98.5% (min)
                                        </p>
                                    </div>
                                    <br /><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Pharma
