import React from 'react'
import Layout from '../../component/layout/Layout'

const News = () => {
    return (
        <Layout>
            <div className="news">
                <h1>We’re attending SASCON Abu Dhabi 2018</h1>
                <p style={{ textAlign: 'justify' }}>
                    Etiam a scelerisque orci, id viverra turpis. Phasellus gravida in libero vitae interdum. Praesent ac vestibulum nunc, id molestie ex. Quisque venenatis metus quis ex rutrum luctus. Curabitur sit amet turpis et est tempus tempus et ut tortor. In aliquam leo eu tellus facilisis, vitae vestibulum lacus sollicitudin. Mauris rutrum imperdiet dolor a lacinia. Integer auctor felis odio, sed pellentesque lacus cursus sed. Donec pretium commodo velit, at mattis urna commodo ut. Cras eros lacus, porttitor vel tincidunt et, mattis at sapien. Praesent pellentesque, tellus ut ultricies elementum, massa justo egestas mi, ut iaculis metus turpis quis urna. Nulla aliquet neque sit amet orci volutpat euismod. Suspendisse et arcu quis leo volutpat varius quis quis lectus. Maecenas cursus at justo id ultrices. Vestibulum nibh eros, faucibus in viverra ut, finibus quis purus. Suspendisse vitae tristique eros, ut laoreet metus. Vivamus nec orci turpis. Sed a nisl tortor. Morbi quam libero, sagittis ac consequat in, convallis eu risus. Duis vel nisi in mauris condimentum ultrices. Proin gravida elit et urna auctor, ut volutpat libero pretium. Etiam vel massa ac felis porttitor aliquam at eu urna. Vivamus facilisis nisl vel congue semper. Nulla in sapien ac ex accumsan malesuada et eu neque. Sed et ex facilisis, tincidunt tortor in, consectetur erat. Pellentesque tempus imperdiet cursus.
                </p>
            </div>
        </Layout>
    )
}

export default News
