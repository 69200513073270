import React from 'react'
import Layout from '../../component/layout/Layout'
import Hero from '../../component/hero/Hero'
import About from '../../component/about/About'
import Product from '../../component/product/Product'
import Footer from '../../component/footer/Footer'

const HomePage = () => {
  return (
    <Layout>
      <Hero/>
      <About/>
      <Product/>
    </Layout>
  )
}

export default HomePage
