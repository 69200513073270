import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import Layout from '../../../../component/layout/Layout'
import { Link } from 'react-router-dom';
import firebaseConfig from '../../../firebase/firebaseConfig';
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

const Mining = () => {
    const [data, setData] = useState({
        imageUrl: '',
        heading: '',
        content: []
      });
    
      useEffect(() => {
        const fetchProducts = async () => {
            const productsCollection = await db.collection('Mining').get();
            const fetchedProducts = productsCollection.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            if (fetchedProducts.length > 0) {
              setData(fetchedProducts[0]); // Assuming you only have one document in 'waterTreatment'
            }
          };
      
          fetchProducts();
      }, []);

    return (
        <Layout>
            <div className="product-banner">
                <div className="product-content">
                    <h1>Mining</h1>
                </div>
            </div>
            <div className="container-xxl py-5">
                <div className="container py-3">
                    <div className="row g-4 w-100">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        </div>
                        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="price-item">
                                <div className="border-bottom p-4 mb-4">
                                    <h5 className="text-primary mb-1">Products</h5>
                                    <h1 className="display-5 mb-0"></h1>
                                </div>
                                <div className="sub-products">
                                    <div className="p-4 pt-0" style={{ textAlign: 'justify' }}>
                                        <Link to="/water" className="dropdown-item">Water Treatment</Link>
                                        <Link to="/waste" className="dropdown-item">Waste Water Treatment</Link>
                                        <Link to="/pulp" className="dropdown-item">Pulp and Paper</Link>
                                        <Link to="/oil" className="dropdown-item">Oil and Gas</Link>
                                        <Link to="/mining" className="dropdown-item">Mining</Link>
                                        <Link to="/sugar" className="dropdown-item">Sugar Industry</Link>
                                        <Link to="/metallurgy" className="dropdown-item">Metallurgy</Link>
                                        <Link to="/auto" className="dropdown-item">Automobile Paint Booth</Link>
                                        <Link to="/miss" className="dropdown-item">Misscellaneous</Link>
                                        <Link to="/pharma" className="dropdown-item">Pharmaceutical</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-8 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="price-item">
                                <div className="border-bottom p-4 mb-4">
                                    <section className="gallery">
                                        <img src={data.imageUrl} className="gallery-item" alt="image" style={{ border: 'black' }} />
                                    </section>
                                </div>
                                <div className="p-4 pt-0">
                                    <div className="section-title-position-relative pb-3">
                                        <h1 className="animated-underline">{data.heading}</h1>
                                    </div>
                                    <ul>
                                        {data.content && data.content.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Mining
