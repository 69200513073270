import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import firebaseConfig from '../../firebase/firebaseConfig';
import "./NavbarFooter.css"

const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore(app);
const storage = firebase.storage();

const HeroSection = () => {
    const [aboutData, setAboutData] = useState([]);
    const [editingItemId, setEditingItemId] = useState(null);
    const [formData, setFormData] = useState({
        image: '',
        title: '',
        subtitle: '',
        description: '',
    });
    const [imageFile, setImageFile] = useState(null);

    useEffect(() => {
        const fetchAboutData = async () => {
            const aboutCollection = await db.collection('heroslides').get();
            const fetchedAboutData = aboutCollection.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setAboutData(fetchedAboutData);
        };

        fetchAboutData();
    }, []);

    const handleDelete = async (id, imageUrl) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this item?");
        if (confirmDelete) {
            await db.collection('heroslides').doc(id).delete();
            if (imageUrl) {
                const storageRef = storage.refFromURL(imageUrl);
                await storageRef.delete();
            }
            setAboutData(aboutData.filter(item => item.id !== id));
        }
    };

    const handleEdit = (item) => {
        setEditingItemId(item.id);
        setFormData(item);
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'image' && files.length > 0) {
            setImageFile(files[0]);
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleUpdate = async () => {
        let updatedData = { ...formData };

        if (imageFile) {
            const oldImageUrl = formData.image;
            const storageRef = storage.ref();
            const fileRef = storageRef.child(`upload/heroslider/img/${imageFile.name}`);
            await fileRef.put(imageFile);
            const newImageUrl = await fileRef.getDownloadURL();
            updatedData.image = newImageUrl;

            if (oldImageUrl) {
                const oldImageRef = storage.refFromURL(oldImageUrl);
                await oldImageRef.delete();
            }
        }

        await db.collection('heroslides').doc(editingItemId).update(updatedData);
        setAboutData(aboutData.map(item => (item.id === editingItemId ? { id: item.id, ...updatedData } : item)));
        setEditingItemId(null);
        setFormData({
            image: '',
            title: '',
            subtitle: '',
            description: '',
        });
        setImageFile(null);
    };

    const handleCancel = () => {
        setEditingItemId(null);
        setFormData({
            image: '',
            title: '',
            subtitle: '',
            description: '',
        });
        setImageFile(null);
    };

    const handleAddNewSlide = () => {
        window.location.href = "/addhero"
    };

    return (
        <div>
            <div className="container mt-5">
                <h2 style={{ textAlign: 'center', marginBottom: '1.5rem' }}>Home Page Hero Section</h2>
                <button
                    className="btn btn-primary mb-3"
                    onClick={handleAddNewSlide}
                    style={{ display: 'block', marginLeft: '56rem' }}
                >
                    <i className="fas fa-plus"></i> Add New Slide
                </button>
                <table className="table table-striped table-bordered table-hover">
                    <thead className="thead-dark">
                        <tr>
                            <th>Image</th>
                            <th>Title</th>
                            <th>SubTitle</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {aboutData.map(item => (
                            <tr key={item.id}>
                                <td><img src={item.image} alt="About Us" style={{ width: '100px', height: '100px', objectFit: 'cover' }} /></td>
                                <td>{item.title}</td>
                                <td>{item.subtitle}</td>
                                <td>{item.description}</td>
                                <td>
                                    <button
                                        className="btn btn-success btn-sm me-2"
                                        style={{ marginBottom: '1.5rem' }}
                                        onClick={() => handleEdit(item)}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() => handleDelete(item.id, item.image)}
                                        style={{ backgroundColor: 'red' }}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {editingItemId && (
                    <div className="popup-container">
                        <div className="popup-form">
                            <h3>Edit About Data</h3>
                            <form>
                                <div className="mb-3">
                                    <label>Image</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="image"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label>Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="title"
                                        value={formData.title}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label>Sub Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="subtitle"
                                        value={formData.subtitle}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label>Description</label>
                                    <textarea
                                        className="form-control"
                                        name="secdescription"
                                        value={formData.description}
                                        onChange={handleChange}
                                    />
                                </div>
                                <button type="button" className="btn btn-success me-2" onClick={handleUpdate}>Update</button>
                                <button type="button" className="btn btn-danger" onClick={handleCancel}>Cancel</button>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default HeroSection;
