import React from 'react'
import Layout from '../../component/layout/Layout'
import "./ProductPage.css"
import { Link } from 'react-router-dom';

const ProductPage = () => {
    return (
        <Layout>
            <div className="product-banner">
                <div className="product-content">
                    <h1>Products & Services</h1>
                </div>
            </div>
            <div className="container-xxl py-5">
                <div className="container py-3">
                    <h4 className='midline'>
                        Products include anionic, non-ionic and cationic polymers of varying charge densities and molecular weights and are made in modern manufacturing facility.
                    </h4>
                    <div className="row g-4 w-100">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        </div>
                        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="price-item">
                                <div className="border-bottom p-4 mb-4">
                                    <h5 className="text-primary mb-1">Products</h5>
                                    <h1 className="display-5 mb-0"></h1>
                                </div>
                                <div className="sub-products">
                                    <div className="p-4 pt-0" style={{ textAlign: 'justify' }}>
                                        <Link to="/water" className="dropdown-item">Water Treatment</Link>
                                        <Link to="/waste" className="dropdown-item">Waste Water Treatment</Link>
                                        <Link to="/pulp" className="dropdown-item">Pulp and Paper</Link>
                                        <Link to="/oil" className="dropdown-item">Oil and Gas</Link>
                                        <Link to="/mining" className="dropdown-item">Mining</Link>
                                        <Link to="/sugar" className="dropdown-item">Sugar Industry</Link>
                                        <Link to="/metallurgy" className="dropdown-item">Metallurgy</Link>
                                        <Link to="/auto" className="dropdown-item">Automobile Paint Booth</Link>
                                        <Link to="/miss" className="dropdown-item">Misscellaneous</Link>
                                        <Link to="/pharma" className="dropdown-item">Pharmaceutical</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-8 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="price-item">
                                <div className="border-bottom p-4 mb-4">
                                    <section className="gallery">
                                        <img src="img/water3.jpg" className="gallery-item" alt="image" style={{ border: 'black' }} />
                                    </section>
                                </div>
                                <div className="p-4 pt-0">
                                    <div className="section-title-position-relative pb-3">
                                        <h1 className="animated-underline">Water Treatment</h1>
                                    </div>
                                    <ul>
                                        <li>Coagulants for Industrial raw water treatment</li>
                                        <li>Coagulants for Municipal water treatment</li>
                                        <li>Non Toxic Anti-slime agents for water circuits</li>
                                        <li>Flocculants and Poly-electrolytes for Municipal and Industrial water treatment.</li>
                                        <li>Filtration Aids for raw water treatment facilities.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    )
}

export default ProductPage
