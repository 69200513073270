import React, { useEffect, useState } from 'react';
import "./About.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import firebaseConfig from '../../pages/firebase/firebaseConfig';

const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore(app);

const About = () => {
    const [image, setImage] = useState('');
    const [maintitle, setMainTitle] = useState('');
    const [mainsubtitle, setMainSubTitle] = useState('');
    const [maindescription, setMainDescription] = useState('');

    const [sectitle, setSecTitle] = useState('');
    const [secdescription, setSecDescription] = useState('');
    const [thirdtitle, setThirdTitle] = useState('');
    const [thirddescription, setThirdDescription] = useState('');

    useEffect(() => {
        AOS.init({ duration: 1000, once: true });

        const fetchAboutData = async () => {
            try {
                const querySnapshot = await db.collection('homeabout').get();
                querySnapshot.forEach(doc => {
                    const data = doc.data();
                    setImage(data.image);
                    setMainTitle(data.title);
                    setMainSubTitle(data.subtitle);
                    setMainDescription(data.description);
                    setSecTitle(data.sectitle);
                    setSecDescription(data.secdescription);
                    setThirdTitle(data.thirdtitle);
                    setThirdDescription(data.thirddescription);
                });
            } catch (error) {
                console.error('Error getting documents:', error);
            }
        };

        fetchAboutData();
    }, []);

    return (
        <div className="container-fluid overflow-hidden py-5 px-lg-0">
            <div className="container about py-5 px-lg-0">
                <div className="row g-5 mx-lg-0">
                    <div className="col-lg-6 ps-lg-0 wow fadeInLeft" data-aos="fade-right" style={{ minHeight: '400px' }}>
                        <div className="position-relative h-100">
                            <img className="position-absolute img-fluid w-100 h-100" src={image} style={{ objectFit: 'cover' }} alt="About Us" />
                        </div>
                    </div>
                    <div className="col-lg-6 about-text wow fadeInUp" data-aos="fade-up" data-aos-duration="3000">
                        <h4 className="text-secondary text-uppercase mb-3">{maintitle}</h4>
                        <h1 className="mb-5"></h1>
                        <p className="mb-5">
                            <span style={{ color: '#d8a603', fontWeight: 'bold' }}>{mainsubtitle}</span> a range of Specialty Chemicals and Water soluble polymers for applications in various industry segments, viz Water treatment, Paper, Sugar, Oil, & Gas, Mining and Metallurgy etc.
                            <br />
                            These products include anionic, non-ionic and cationic polymers of varying charge densities and molecular weights and are made in a modern manufacturing facility.
                        </p>
                        <div className="row g-4 mb-5" data-aos="fade-up" data-aos-duration="3000">
                            <div className="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                                <i className="fa fa-globe fa-3x text-primary mb-3"></i>
                                <h5>{sectitle}</h5>
                                <p className="m-0" style={{ textAlign: 'justify' }}>
                                   {secdescription}
                                </p>
                            </div>
                            <div className="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                                <i className="fa fa-shipping-fast fa-3x text-primary mb-3"></i>
                                <h5>{thirdtitle}</h5>
                                <p className="m-0" style={{ textAlign: 'justify' }}>
                                  {thirddescription}
                                </p>
                            </div>
                        </div>
                        <a href="/about" className="btn btn-primary py-3 px-5">Explore More</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;
