import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import firebaseConfig from '../../../firebase/firebaseConfig';
import "./HeroForm.css"


const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore(app);
const storage = firebase.storage();

const HeroForm = () => {
    const [subtitle, setSubtitle] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!image) {
            alert('Please select an image');
            return;
        }

        const imageRef = storage.ref(`upload/heroslider/img/${image.name}`);
        try {
            await imageRef.put(image);
            const imageUrl = await imageRef.getDownloadURL();
            await db.collection('heroslides').add({
                subtitle,
                title,
                description,
                image: imageUrl,
            });
            alert('Slide added successfully');
            setSubtitle('');
            setTitle('');
            setDescription('');
            setImage(null);
            window.location.href = "/admin"
        } catch (error) {
            console.error('Error uploading image: ', error);
            alert('Error adding slide');
        }
    };

    return (
        <div>
            <h2 style={{ textAlign: 'center', marginBottom: '1.5rem' }}>Add a New Slide</h2>
            <form className='form' onSubmit={handleSubmit}>
                <div className='InputField'>
                    <label htmlFor="image">Image</label>
                    <input
                        type="file"
                        id="image"
                        accept="image/*"
                        onChange={(e) => setImage(e.target.files[0])}
                        required
                    />
                </div>
                <div className='InputField'>
                    <label htmlFor="subtitle">Subtitle</label>
                    <input
                        type="text"
                        id="subtitle"
                        value={subtitle}
                        onChange={(e) => setSubtitle(e.target.value)}
                        required
                    />
                </div>
                <div className='InputField'>
                    <label htmlFor="title">Title</label>
                    <input
                        type="text"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="description">Description</label>
                    <textarea
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    ></textarea>
                </div>
                <button type="submit">Add Slide</button>
            </form>
        </div>
    );
}

export default HeroForm
