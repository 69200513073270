import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import Loader from '../loader/Loader';

const Layout = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        const handleStart = () => setLoading(true);
        const handleComplete = () => setLoading(false);

        handleStart(); // Start loading on initial mount

        // Simulate a loading process or use actual data fetching
        const timeout = setTimeout(handleComplete, 2500); // Adjust timeout as needed

        return () => clearTimeout(timeout); // Cleanup timeout on unmount
    }, [location]);

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Navbar />
                    <div className="content">
                        {children}
                    </div>
                    <Footer />
                </>
            )}
        </div>
    );
};

export default Layout;
