import React from 'react'
// import Dashboard from './Dashboard'
import NavbarFooter from './dash-pages/NavbarFooter'

const Admin = () => {
  return (
    <div>
      <NavbarFooter/>
    </div>
  )
}

export default Admin
