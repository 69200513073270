// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCOX8eBn8LkHyg9j4kKTwshi8vWtspxs9E",
    authDomain: "spechem-bec01.firebaseapp.com",
    projectId: "spechem-bec01",
    storageBucket: "spechem-bec01.appspot.com",
    messagingSenderId: "121306462466",
    appId: "1:121306462466:web:32408321bf0b1ea49e2a1b",
    measurementId: "G-Y5KFF88EST"
  };

  export default firebaseConfig;