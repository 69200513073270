import React, { useEffect, useState } from 'react';
import Dashboard from '../Dashboard';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import firebaseConfig from '../../firebase/firebaseConfig';
import './NavbarFooter.css'
import HeroSection from './HeroSection';
import AboutSection from './AboutSection';
import HomeProduct from './HomeProduct';

const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore(app);

const NavbarFooter = () => {
    const [contactInfo, setContactInfo] = useState([]);
    const [editingItemId, setEditingItemId] = useState(null);
    const [formData, setFormData] = useState({
        email: '',
        phone1: '',
        phone3: '',
        phone2: '',
        twitter: '',
        facebook: '',
        youtube: '',
        linkedin: ''
    });

    useEffect(() => {
        const fetchContactInfo = async () => {
            const contactCollection = await db.collection('navbar-footer').get();
            const fetchedContactInfo = contactCollection.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setContactInfo(fetchedContactInfo);
        };

        fetchContactInfo();
    }, []);

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this item?");
        if (confirmDelete) {
            await db.collection('navbar-footer').doc(id).delete();
            setContactInfo(contactInfo.filter(item => item.id !== id));
        }
    };


    const handleEdit = (item) => {
        setEditingItemId(item.id);
        setFormData(item);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleUpdate = async () => {
        await db.collection('navbar-footer').doc(editingItemId).update(formData);
        setContactInfo(contactInfo.map(item => (item.id === editingItemId ? { id: item.id, ...formData } : item)));
        setEditingItemId(null);
        setFormData({
            email: '',
            phone1: '',
            phone3: '',
            phone2: '',
            twitter: '',
            facebook: '',
            youtube: '',
            linkedin: ''
        });
    };

    const handleCancel = () => {
        setEditingItemId(null);
        setFormData({
            email: '',
            phone1: '',
            phone3: '',
            phone2: '',
            twitter: '',
            facebook: '',
            youtube: '',
            linkedin: ''
        });
    };

    return (
        <div>
            <Dashboard />
            <div className="container mt-5">
                <h2 style={{ textAlign: 'center',marginBottom: '1.5rem' }}>Navbar and Footer</h2>
                <table className="table table-striped table-bordered table-hover">
                    <thead className="thead-dark">
                        <tr>
                            <th>Email</th>
                            <th>Phone (UK)</th>
                            <th>Fax (UK)</th>
                            <th>Phone (US)</th>
                            <th>Twitter</th>
                            <th>Facebook</th>
                            <th>YouTube</th>
                            <th>LinkedIn</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contactInfo.map(item => (
                            <tr key={item.id}>
                                <td>{item.email}</td>
                                <td>{item.phone1}</td>
                                <td>{item.phone3}</td>
                                <td>{item.phone2}</td>
                                <td><a href={item.twitter} target="_blank" rel="noopener noreferrer">Twitter</a></td>
                                <td><a href={item.facebook} target="_blank" rel="noopener noreferrer">Facebook</a></td>
                                <td><a href={item.youtube} target="_blank" rel="noopener noreferrer">YouTube</a></td>
                                <td><a href={item.linkedin} target="_blank" rel="noopener noreferrer">LinkedIn</a></td>
                                <td>
                                    <button
                                        className="btn btn-success btn-sm me-2"
                                        style={{ marginBottom: '1.5rem' }}
                                        onClick={() => handleEdit(item)}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() => handleDelete(item.id)}
                                        style={{ backgroundColor: 'red' }}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {editingItemId && (
                    <div className="popup-container">
                        <div className="popup-form">
                            <h3>Edit Contact Info</h3>
                            <form>
                                <div className="mb-3">
                                    <input
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="Email"
                                    />
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="phone1"
                                        value={formData.phone1}
                                        onChange={handleChange}
                                        placeholder="Phone (UK)"
                                    />
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="phone3"
                                        value={formData.phone3}
                                        onChange={handleChange}
                                        placeholder="Fax (UK)"
                                    />
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="phone2"
                                        value={formData.phone2}
                                        onChange={handleChange}
                                        placeholder="Phone (US)"
                                    />
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="twitter"
                                        value={formData.twitter}
                                        onChange={handleChange}
                                        placeholder="Twitter URL"
                                    />
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="facebook"
                                        value={formData.facebook}
                                        onChange={handleChange}
                                        placeholder="Facebook URL"
                                    />
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="youtube"
                                        value={formData.youtube}
                                        onChange={handleChange}
                                        placeholder="YouTube URL"
                                    />
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="linkedin"
                                        value={formData.linkedin}
                                        onChange={handleChange}
                                        placeholder="LinkedIn URL"
                                    />
                                </div>
                                <button type="button" className="btn btn-success me-2" onClick={handleUpdate}>Update</button>
                                <button type="button" className="btn btn-danger" onClick={handleCancel}>Cancel</button>
                            </form>
                        </div>
                    </div>
                )}
            </div>
            <HeroSection/>
            <AboutSection/>
            <HomeProduct/>
        </div>
    );
};

export default NavbarFooter;
