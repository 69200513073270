import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import firebaseConfig from '../../pages/firebase/firebaseConfig';
import { Link } from 'react-router-dom';
import './Navbar.css';


const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore(app);


const Navbar = () => {
  const [contactInfo, setContactInfo] = useState({ phone1: '', phone2: '' });

  useEffect(() => {
    const fetchContactInfo = async () => {
      const contactDoc = await db.collection('navbar-footer').doc('XhlsFEOHJ7YNkT4aE01k').get();
      if (contactDoc.exists) {
        setContactInfo(contactDoc.data());
      }
    };

    fetchContactInfo();
  }, []);



  useEffect(() => {
    const handleScroll = () => {
      const stickyTop = document.querySelector('.sticky-top');
      if (window.scrollY > 300) {
        stickyTop.style.top = '0px';
      } else {
        stickyTop.style.top = '-100px';
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);



  const [productDropdownOpen, setProductDropdownOpen] = useState(false);

  const handleProductClick = () => {
    setProductDropdownOpen(!productDropdownOpen);
  };

  const handleArrowClick = () => {
    if (window.innerWidth <= 992) {
      setProductDropdownOpen(!productDropdownOpen);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg bg-white navbar-light shadow border-top border-5 border-primary sticky-top p-0 w-100">
      <Link to="/" className="logo">
        <img src="/logo.png" alt="Speciality Chemicals" />
      </Link>
      <button
        type="button"
        className="navbar-toggler me-4"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto p-4 p-lg-0">
          <Link to="/" className="nav-item nav-link active">
            Home
          </Link>
          <Link to="/about" className="nav-item nav-link">
            About Us
          </Link>
          <div className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              onClick={handleProductClick}
              role="button"
              href="/product"
            >
              Products
            </a>
            <div
              className={`dropdown-menu ${productDropdownOpen ? 'show' : ''}`}
              aria-labelledby="navbarDropdown"
            >
              <Link className="dropdown-item" id="nav-link" to="/water">
                Water Treatment
              </Link>
              <Link className="dropdown-item" id="nav-link" to="/waste">
                Waste Water Treatment
              </Link>
              <Link className="dropdown-item" id="nav-link" to="/pulp">
                Pulp and Paper
              </Link>
              <Link className="dropdown-item" id="nav-link" to="/oil">
                Oil and Gas
              </Link>
              <Link className="dropdown-item" id="nav-link" to="/mining">
                Mining
              </Link>
              <Link className="dropdown-item" id="nav-link" to="/sugar">
                Sugar Industry
              </Link>
              <Link className="dropdown-item" id="nav-link" to="/metallurgy">
                Metallurgy
              </Link>
              <Link className="dropdown-item" id="nav-link" to="/auto">
                Automobile Paint Booth
              </Link>
              <Link className="dropdown-item" id="nav-link" to="/miss">
                Miscellaneous
              </Link>
              <Link className="dropdown-item" id="nav-link" to="/pharma">
                Pharmaceutical
              </Link>
            </div>
          </div>
          <Link to="/contact" className="nav-item nav-link">
            Contact
          </Link>
          <Link to="/login" className="nav-item nav-link">
            Login
          </Link>
        </div>
        <h5 className="m-0 pe-lg-5 d-none d-lg-block">
          <i className="fa fa-headphones text-primary me-3"></i>{contactInfo.phone1}
          <br />
          <i className="fa fa-headphones text-primary me-3"></i>{contactInfo.phone2}
        </h5>
      </div>
    </nav>
  );
};

export default Navbar;
