import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import firebaseConfig from "../../firebase/firebaseConfig";
import Dashboard from '../Dashboard';

const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore(app);

const ContactDetails = () => {
    const [contactData, setContactData] = useState([])
    useEffect(() => {
        const fetchAboutData = async () => {
            const aboutCollection = await db.collection('contacts').get();
            const fetchedAboutData = aboutCollection.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setContactData(fetchedAboutData);
        };

        fetchAboutData();
    }, []);
    return (
        <div>
            <Dashboard/>
            <div className="container mt-5">
                <h2 style={{ textAlign: 'center', marginBottom: '1.5rem' }}>Contact Form Data</h2>
                <table className="table table-striped table-bordered table-hover">
                    <thead className="thead-dark">
                        <tr>
                            <th>Time and Data</th>
                            <th>Name</th>
                            <th>Email ID</th>
                            <th>Phone Number</th>
                            <th>Company Name</th>
                            <th>Country Name</th>
                            <th>Subject</th>
                            <th>Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contactData.map(item => (
                            <tr key={item.id}>
                                <td>{new Date(item.timestamp.seconds * 1000).toLocaleString()}</td>
                                <td>{item.fname}</td>
                                <td>{item.email}</td>
                                <td>{item.phone}</td>
                                <td>{item.company}</td>
                                <td>{item.country}</td>
                                <td>{item.subject}</td>
                                <td>{item.message}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ContactDetails;
