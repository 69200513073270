import React from 'react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import './Dashboard.css'
import firebaseConfig from '../firebase/firebaseConfig';
import swal from 'sweetalert2';



const firebaseApp = firebase.initializeApp(firebaseConfig);
const auth = firebaseApp.auth();

const Dashboard = () => {
  const handleLogout = async () => {
    try {
      await auth.signOut();
      // Redirect or perform other actions after successful logout
      swal.fire('Success', 'Logout Successfully', 'success');
      window.location.href='/login';
    } catch (error) {
      swal.fire('Error during logout: ', error);
      // Handle errors during logout
    }
  };
  return (
    <div>
      <div>
        <header className='header-dash'>
          <h1>Admin Dashboard</h1>
        </header>

        <nav className='dash-nav'>
          {/* <a href="/dashboard">Products</a> */}
          <a href="/navbarfooter">Home Page</a>
          <a href="/contactform">Contact Page</a>
        </nav>

        <button className='logout' onClick={handleLogout}>Logout <i class="fa-solid fa-right-from-bracket"></i></button>
      </div>
    </div>
  )
}

export default Dashboard
