import React from 'react'
import Layout from '../../component/layout/Layout'


const AboutPage = () => {
    return (
        <Layout>
            <div className="owl-carousel-item position-relative">
                <div className="image-container w-100 h-100" id='aboutimg'>
                    <img className="img-fluid" src="/R.png" alt="Description" />
                </div>
                <div
                    className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
                    style={{ background: 'rgba(24, 23, 23, 0.5)' }}
                >
                    <div className="container">
                        <div className="row justify-content-start">
                            <div className="col-10 col-lg-8">
                                <h1 className="display-3 text-white mb-3 animated slideInDown">About Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid overflow-hidden py-5 px-lg-0">
                <div className="container about py-5 px-lg-0">
                    <div className="row g-5 mx-lg-0">
                        <div
                            className="col-lg-6 ps-lg-0 wow fadeInLeft"
                            data-wow-delay="0.1s"
                            style={{ minHeight: '400px' }}
                        >
                            <div className="position-relative h-100">
                                <div className="image-container-about">
                                    <img
                                        className="position-absolute img-fluid w-100 h-100"
                                        src="/about.jpg"
                                        style={{ objectFit: 'cover' }}
                                        alt="About Us"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 about-text wow fadeInUp" data-wow-delay="0.3s">
                            <h4 className="text-secondary text-uppercase mb-3">About Us</h4>
                            <h1 className="mb-5"></h1>
                            <div className="about-para">
                                <p className="mb-5">
                                    Specialty Chemicals UK Ltd (SCUK) offers a range of Specialty Chemicals and Water soluble polymers for applications in various industry segments, viz Water treatment, Paper, Sugar Oil, & Gas, Mining and Metallurgy etc. These products include anionic, non-ionic and cationic polymers of varying charge densities and molecular weights and are made in a modern manufacturing facility.
                                </p>
                            </div>
                            <h4 className="text-secondary text-uppercase mb-3">Our Features</h4>
                            <h1 className="mb-5">We Are Trusted Chemical Company Since 1984</h1>
                            <div className="row g-4 mb-5">
                                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                                    <i className="fa fa-globe fa-3x text-primary mb-3"></i>
                                    <h5>Worldwide Service</h5>
                                    <p className="m-0" style={{ textAlign: 'justify' }}>
                                        Products manufactured by the company are exported regularly to various countries in Europe, America, Africa, Middle East, and Asia.
                                    </p>
                                </div>
                                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                                    <i className="fa fa-shipping-fast fa-3x text-primary mb-3"></i>
                                    <h5>On Time Service and Delivery</h5>
                                    <p className="m-0" style={{ textAlign: 'justify' }}>
                                        We make sure that all the drugs that we deliver cure us and keep us safe from various diseases.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AboutPage
